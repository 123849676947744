<template>
    <div class="bg-white flex flex-col h-screen">
        <div class="margin-auto-override container w-full flex items-center justify-center">
            <div class="flex items-center px-25px py-25px text-black text-center">
                <div class="lg:w-full flex flex-col items-center justify-center">
                    <p class="text-xxLarge">Financial Reviews</p>
                    <p class="my-50px">Lorem ipsum dolor sit amet, consectetur<br>adipiscing elit, sed do eiusmod tempor.</p>
                    <router-link
                    class="btn btn--arrow bg-teal px-6 py-4 rounded-full duration-300 text-white hover:bg-black hover:text-white"
                    to="/barriers"
                    >
                    Continue your review
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "TechnologyIntro",
};
</script>
